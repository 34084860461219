<template>
  <div id="app">
    <!-- 头部区域 -->
    <!-- <div class="header">
      <Header></Header>
    </div> -->

    <!-- <div class="bodys">
      <BodyItem></BodyItem>
    </div> -->
    <!-- 你好sss -->
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
    <!-- <router-view></router-view> -->

    <!-- <div class="login">
      <Login></Login>
    </div> -->

  </div>
</template>

<script>
// import { RouterView } from 'vue-router';

// import Header from '@/components/HeaderItem/HeaderItem.vue'
// import BodyItem from '@/components/BodyItem.vue'
// import Login from '@/components/LoginItem/LoginItem.vue'

export default {
  name: 'App',
  components: {
    // RouterView
}
}
</script>

<style>
.header {
  
  /* background-color: cadetblue; */
  background-color: #284eb875;
}
body{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.login{
  width: 100vw;
  height: 100vh;
}

#app {
  /* width: 100%;
  height: 100%; */
  width: 100vw;
  height: 100vh;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: start; */
}

</style>
