import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

// const router = [
//     {
//         path: '/',
//         name: 'Home',
//         component: () => import('@/components/MsgItem/MsgItem.vue')
//     },
// ]

const router = new VueRouter({
    routes: [
        {
            path: '/',
            name: 'Main',
            component: () => import('@/components/HeaderAddList/HeaderAddList.vue'),
            redirect: '/home',
            children: [
                {
                    path: '/home',
                    name: 'Home',
                    component: () => import('@/components/MsgItem/MainItem.vue')
                    // component: () => import('@/components/LoginItem/LoginItem.vue')
                },
                {
                    path: '/outmode',
                    name: 'Outinfo',
                    component: () => import('@/components/MsgItem/OutMode.vue')
                },
                {
                    path: '/inmode',
                    name: 'Ininfo',
                    component: () => import('@/components/MsgItem/InMode.vue')
                },
                {
                    path: '/leimode',
                    name: 'Fenlei',
                    component: () => import('@/components/MsgItem/fenLei.vue')
                },
                {
                    path: '/myinfo',
                    name: 'Myinfo',
                    component: () => import('@/components/MsgItem/MyInfo.vue')
                },
                {
                    path: '/target',
                    name: 'Target',
                    component: () => import('@/components/MsgItem/setTarget.vue')
                },
                {
                    path: '/licheng',
                    name: 'Licheng',
                    component: () => import('@/components/MsgItem/LiCheng.vue')
                }
            ]
        },
        {
            path: '/login',
            name: 'Login',
            component: () => import('@/components/LoginItem/LoginItem.vue')
        }
    ]
})

router.beforeEach((to, from, next) => {
    if (to.path === '/login') {
        next();
    } else {
        let token = localStorage.getItem('token');
        if (token === null || token === '') {
            next('/login');
        } else {
            next();
        }
    }
})


export default router