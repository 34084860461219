import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router/index.js'
// import wxzforDate from '@/utils/dateFormartFun/dateFormartFunPlugins.js'

Vue.use(ElementUI);

// 全局注册自定义的时间格式修正插件
// Vue.use(wxzforDate)

Vue.config.productionTip = false

Vue.prototype.$http = 'http://licai.axz99.cn:3307'

new Vue({
  el: '#app',
  router,
  render: h => h(App),
}).$mount('#app')
